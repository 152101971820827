import axios from '@/utils/axios'

// 获取用户
export function listUser(params){
  return axios({
    url:'/api/admin/user/front/listUser',
    method:'get',
    params
  })
}


// 创建用户
export function addUserInfo(data){
  return axios({
    url:'/api/admin/login/front/addUserInfo',
    method:'put',
    data
  })
}


// 编辑用户
export function updateUser(data){
  return axios({
    url:'/api/admin/user/front/updateUser',
    method:'put',
    data
  })
}

// 修改密码
export function editUserInfo(data){
  return axios({
    url:'/api/admin/user/front/editUserInfo',
    method:'put',
    data
  })
}



// 编辑回显
export function getUser(params){
  return axios({
    url:'/api/admin/user/front/getUser',
    method:'get',
    params
  })
}

// 删除用户
export function deleteUserById(params){
  return axios({
    url:'/api/admin/user/front/deleteUserById',
    method:'get',
    params
  })
}

