<template>
  <div class="noticeInformationBox">
    <div class="searchBox">
      <div class="searchLeft">
        <ul>
          <li>
            <p class="searchTitle">姓名：</p>
            <el-input v-model="name" placeholder="请输入姓名"></el-input>
          </li>
          <li>
            <p class="searchStatus">账户：</p>
            <el-input v-model="username" placeholder="请输入账户"></el-input>
          </li>
          <li>
            <el-button class="searchBtn" type="warning" @click="searchFun">查询</el-button>
          </li>
        </ul>
      </div>

      <div class="searchRight">
        <el-button class="addBtn" @click="addNoticeFun">添加用户</el-button>
      </div>
    </div>

    <div class="noticeInformationContent">
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" label="序号"></el-table-column>
          <el-table-column align="center" prop="name" label="姓名" width="120"></el-table-column>
          <el-table-column align="center" prop="username" label="账户" width="160"></el-table-column>
          <el-table-column align="center" prop="englishName" label="角色" ></el-table-column>
          <el-table-column align="center" prop="sex" label="性别" width="80"></el-table-column>
          <el-table-column align="center" prop="mobilePhone" label="手机号" width="130"></el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <div class="signUpFlex">
                <p class="signUpColor" @click="editFun(scope.row.id)">编辑</p>
                <p class="signUpColor" @click="passwordEdit(scope.row.id)">更改密码</p>
                <p class="signUpColor" @click="deleteFun(scope.row.id)">删除</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          layout="prev, pager, next"
          :page-size="10"
          :current-page="page"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="type=='add'?'新增':'编辑'" :visible.sync="dialogVisible" top="80px" width="600px" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="formBox" v-if="dialogVisible">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px">
          <el-row>
            <el-col :span="24">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model.trim="form.name" placeholder="请输入姓名" clearable></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="账户" prop="username">
                    <el-input v-model="form.username" placeholder="请输入账户" :readonly="type=='edit'"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="type=='add'">
              <el-form-item label="密码" prop="password">
                <el-input type="form.password" v-model.trim="form.password" placeholder="请输入密码" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="性别">
                <el-select class="filter-item width-100" v-model="form.sex" placeholder="请选择">
                  <el-option v-for="item in sexOptions" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="手机" prop="mobilePhone">
                <el-input v-model="form.mobilePhone" placeholder="请输入手机"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="角色">
                <el-input v-model="form.englishName" placeholder="请输入角色"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="closeBox">
        <el-button  class="preservationBtn" :loading="loading" @click="submitFun">确定</el-button>
        <el-button style="width:100px" @click="handleClose">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="更改密码" :visible.sync="passwordDialog" width="500px" top="80px" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form label-width="130px" :model="password" :rules="passwrodRules" ref="password">
        <el-form-item label="新密码" prop="passwordValue">
          <el-input v-model="password.passwordValue" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model="password.confirmPassword" show-password></el-input>
        </el-form-item>
      </el-form>
      <div class="closeBox">
        <el-button  class="preservationBtn" :loading="loading" @click="passSubmitFun">确定</el-button>
        <el-button style="width:100px" @click="passwordClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {listUser,addUserInfo,updateUser,getUser,deleteUserById,editUserInfo} from '@/api/userManagement/userManagement'
export default {
  data() {
    return {
      username:'',
      name:'',
      dialogVisible:false,
      passwordDialog:false,
      loading:false,
      type:'add',
      id:'',
      total:0,
      page:1,
      tableData:[],
      password:{},
      sexOptions: ['男', '女'],
      form:{},
      rules: {
        name: [
          {
            required: true,
            message: '请输入用户',
            trigger: 'blur'
          },
        ],
        username: [
          {
            required: true,
            message: '请输入账户',
            trigger: 'blur'
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          },
        ],
			},

      passwrodRules:{
        passwordValue:[
          {required:true,message: '请输入密码'},
          {min: 6,max: 20,message: '密码长度必须在6-20位之间',trigger: 'blur'}
        ],
        confirmPassword:[
          {required:true,message: '请确认密码'},
          {min: 6,max: 20,message: '密码长度必须在6-20位之间',trigger: 'blur'}
        ]
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    // 搜索
    searchFun(){
      this.page = 1;
      this.getData()
    },
    // 获取列表
    getData(){
      let data = {
        page:this.page,
        limit:10,
        name:this.name,
        username:this.username
      }

      listUser(data).then(res=>{
        if(res.status == 200){
          this.tableData = res.data.rows;
          this.total = res.data.total;
        }
      })
    },
    // 创建用户
    addNoticeFun(){
      this.form = {};
      this.type = 'add'
      this.dialogVisible = true;
    },
    // 修改密码
    passwordEdit(id){
      this.id = id;
      this.passwordDialog = true;
    },
    // 编辑
    editFun(id){
      this.id = id;
      getUser({id}).then(res=>{
        if(res.status == 200){
          this.form = res.data;
          this.type = 'edit';
          this.dialogVisible = true;
        }
      })
    },
    // 新增用户确定
    submitFun(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let fn;
          let data = {
            ...this.form
          };
          if(this.type=='add'){
            fn = addUserInfo
          }else{
            fn = updateUser;
            data.id = this.id;
          }
          this.loading = true;
          fn(data).then(res=>{
            if(res.status == 200){
              this.dialogVisible = false;
              this.$message.success(this.type=='add'?'新增成功':'编辑成功');
              this.searchFun()
            }else{
              this.$message.error(res.message)
            }
            this.loading = false;
          }).catch(error=>{
            this.loading = false;
          })
        }else{
          console.log('error submit!!');
          return false;
        }
      })
      
    },
    
    // 删除
    deleteFun(id){
      this.$confirm('你确认要将删除该用户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteUserById({id}).then(res=>{
          if(res.status == 200){
            this.$message.success('删除成功！')
            this.searchFun()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });          
      });
    },
    currentChange(val){
      this.page = val;
      this.getData()
    },
    // 新增用户
    handleClose(){
      this.dialogVisible = false;
    },
    // 修改密码确定
    passSubmitFun(){
      this.$refs.password.validate((valid) => {
					if (valid) {
            if(this.password.passwordValue===this.password.confirmPassword){
              let data = {
                id:this.id,
                password:this.password.passwordValue
              }
              editUserInfo(data).then(res=>{
                if(res.status == 200){
                  this.$message.success('修改成功');
                  this.passwordDialog = false;
                }
              })
            }else{
              this.$message.error('两次密码不同')
            }
          }else{
            return false;
          }
      })
      
    },
    // 更改密码
    passwordClose(){
      this.passwordDialog = false;
    },
  }
}
</script>
<style lang="scss" scoped>
  .noticeInformationBox{
    .searchBox{
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .searchLeft{
        ul{
          display: flex;
          li{
            display: flex;
            align-items: center;
            margin-right: 30px;

            .searchTitle{
              min-width: 80px;
              text-align: right;
            }
            .searchStatus{
              min-width: 50px;
              text-align: right;
            }
          }
        }
      }

      .searchRight{
        .addBtn{
          background: #bf162a;
          color: #fff;
          border: none;
        }
      }
    }

    .noticeInformationContent{
      margin-top: 20px;
      .signUpFlex{
        display: flex;
        justify-content: center;
        .signUpColor{
          color: rgb(17, 166, 234);
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }

    .formBox{
      width: 400px;
    }
    .closeBox{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      padding: 10px 70px;
      border-top: 1px solid #DADADA;

      .closeBtn{
        width: 100px;
        height: 40px;
        background: rgb(189, 25, 44);
        border: none;
      }
      .preservationBtn{
        border: 1px solid #BD192C;
        color: #BD192C;
         width: 100px;
      }
    }
  }
</style>